import React from "react";

import "./css/main.css";
import "./css/header.css";
import "./css/bootstrap.min.css";
import "./css/contact.css";
import "./css/about.css";
import "./css/philosophy.css";
import "./css/port-part.css";
import "./css/value.css";

import { Link } from "react-scroll";

function App() {
  return (
    <div className="App">
      <>
        {/* header */}
        <header>
          <nav id="navigation" className="navbar navbar-expand-lg fixed-top">
            <a className="navbar-brand" href="#">
              <img
                src={require("./Icons/PNG/Logo.png").default}
                height={30}
                alt="Logo"
              />
            </a>
            <button
              className="navbar-toggler custom-navbar"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <span class="my-1 mx-2 close">x</span> */}
              <span class="my-1 mx-2 close">
                <img
                  src={require("./Icons/Icon.png").default}
                  height={28}
                  alt="close"
                />
              </span>
              <span className="navbar-toggler-icon"></span>
            </button>
            {/* navbar */}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-lg-auto">
                <li className="nav-item active">
                  <Link
                    className="nav-link"
                    to="about"
                    smooth={true}
                    duration={1000}
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="philosophy"
                    smooth={true}
                    duration={1000}
                  >
                    Philosophy
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="value"
                    smooth={true}
                    duration={1000}
                  >
                    Value Proposition
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="portfolios"
                    smooth={true}
                    duration={1000}
                  >
                    {" "}
                    Portfolios
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="partners"
                    smooth={true}
                    duration={1000}
                  >
                    {" "}
                    Partners
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        {/* body */}
        <main className="wrapper">
          <section className="banner">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-12">
                  <div className="description ">
                    <h1>
                      Connecting
                      <br /> <span>the</span> decentralized <span>world.</span>
                    </h1>
                    <div className="col-sm-6 col-md-6">
                      <p>
                        Here we focus on providing you the best conditions for
                        your dream projects to prosper in.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="logoPartner">
            <div className="container">
              <div className="row">
                <div className="slider">
                  <div className="slider-track">
                    <div className="slide">
                      <img
                        src={require("./Grayscale logos/PNG/splyt.png").default}
                        alt="logo"
                      />
                      <img
                        src={require("./Grayscale logos/PNG/aioz.png").default}
                        alt="logo"
                      />
                      <img
                        src={require("./Grayscale logos/PNG/atlas.png").default}
                        alt="logo"
                      />
                      <img
                        src={require("./Grayscale logos/PNG/fatfi.png").default}
                        alt="logo"
                      />
                      <img
                        src={require("./Grayscale logos/PNG/globe.png").default}
                        alt="logo"
                      />
                      <img
                        src={
                          require("./Grayscale logos/PNG/kickpad.png").default
                        }
                        alt="logo"
                      />
                      <img
                        src={
                          require("./Grayscale logos/PNG/nfttech.png").default
                        }
                        alt="logo"
                      />
                      <img
                        src={
                          require("./Grayscale logos/PNG/roseon.png").default
                        }
                        alt="logo"
                      />
                      <img
                        src={
                          require("./Grayscale logos/PNG/showcase.png").default
                        }
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* about section */}
          <div id="about" href="#about"></div>
          <section className="about">
            <div className="container">
              <div className="row">
                <div className="col-sm-4 col-md-4 offset-md-8">
                  <h1>WHO WE ARE</h1>
                  <h2>About Us</h2>
                </div>
                <div className="col-sm-4 col-md-4 offset-md-8 mb-4">
                  <div className="boxAbout">
                    <img
                      src={
                        require("./Icons/Blues/PNG/With round-8.png").default
                      }
                      className="card-img-top"
                      alt="icon"
                    />
                    <h3>Investment arm of KardiaChain</h3>
                    <p>
                      Kardia Ventures is a dedicated Crypto-centric fund and
                      served as the Investment Arm for KardiaChain, the leading
                      blockchain technology company in Vietnam.
                    </p>
                  </div>
                </div>
                <div className="col-sm-4" />
                <div className="col-sm-4 col-md-4 mgb30">
                  <div className="boxAbout ">
                    <img
                      src={
                        require("./Icons/Blues/PNG/With round-9.png").default
                      }
                      className="card-img-top"
                      alt="icon"
                    />
                    <h3>Missions</h3>
                    <p>
                      Kardia Ventures is built based on our strong belief in
                      blockchain technology to disrupt existing industries and
                      create immense business opportunities. The rise of
                      decentralized trust based networks will not only transform
                      existing industries beyond recognition, it also has the
                      power to provide billions of underserved people with
                      access to financial services.
                    </p>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4">
                  <div className="boxAbout borderColor">
                    <img
                      src={
                        require("./Icons/Blues/PNG/With round-10.png").default
                      }
                      className="card-img-top"
                      alt="icon"
                    />
                    <h3>Expertise</h3>
                    <p>
                      The Kardia Ventures portfolios is experienced in both
                      traditional finance and emergent blockchain technology. We
                      target investments in Seed, Private and Public Rounds
                      launched by promising projects that seek to decentralize
                      the global economy and add value to the blockchain
                      ecosystem.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* philosophy */}
          <div id="philosophy" href="#philosophy"></div>
          <section className="philosophy">
            <div className="container">
              <h1 className="text-left">FROM THE GROUND UP</h1>
              <h2 className="text-left">Our Investment Philosophy</h2>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-md-6 col-lg-6">
                      <div className="boxPhi">
                        <img
                          src={
                            require("./Icons/Blues/PNG/With round-4.png")
                              .default
                          }
                          className="img-top"
                          alt="icon"
                        />
                        <div>
                          <h3>Do. Dare. Deliver.</h3>
                          <p>
                            We are the dreamers, doers, and disruptor who wish
                            to push the world toward a better future.
                          </p>
                        </div>
                      </div>
                      <div className="boxPhi hidBox">
                        <img
                          src={
                            require("./Icons/Blues/PNG/With round-6.png")
                              .default
                          }
                          className="img-top"
                          alt="icon"
                        />
                        <div>
                          <h3>Macro vision, Micro execution</h3>
                          <p>
                            Our investment team and advisors lineup have very
                            deep understanding of the industry trend and
                            actively support our entrepreneurs’ vision, mission
                            and implementation.
                          </p>
                        </div>
                      </div>
                      <div className="boxPhi3 borderColor">
                        <img
                          src={
                            require("./Icons/Blues/PNG/With round-5.png")
                              .default
                          }
                          className="img-top"
                          alt="icon"
                        />
                        <div>
                          <h3>Inspire the Bold</h3>
                          <p>
                            Born out of KardiaChain, we value and support
                            pioneers and doers to push the edges of the
                            ecosystem and drive value and progress.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 ">
                      <div className="boxPhi3">
                        <img
                          src={
                            require("./Icons/Blues/PNG/With round-6.png")
                              .default
                          }
                          className="img-top"
                          alt="icon"
                        />
                        <div>
                          <h3>Macro vision, Micro execution</h3>
                          <p>
                            Our investment team and advisors lineup have very
                            deep understanding of the industry trend and
                            actively support our entrepreneurs’ vision, mission
                            and implementation.
                          </p>
                        </div>
                      </div>
                      <div className="boxPhi hidBox borderColor">
                        <img
                          src={
                            require("./Icons/Blues/PNG/With round-5.png")
                              .default
                          }
                          className="img-top"
                          alt="icon"
                        />
                        <div>
                          <h3>Inspire the Bold</h3>
                          <p>
                            Born out of KardiaChain, we value and support
                            pioneers and doers to push the edges of the
                            ecosystem and drive value and progress.
                          </p>
                        </div>
                      </div>
                      <div className="boxPhi">
                        <img
                          src={
                            require("./Icons/Blues/PNG/With round-7.png")
                              .default
                          }
                          className="img-top"
                          alt="icon"
                        />
                        <div>
                          <h3>Propel new Disruptors</h3>
                          <p>
                            We cut through the noise to find and back
                            entrepreneurs and companies eager to be part of the
                            greatest technological revolution of our lifetimes
                            since the Internet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* value section */}
          <div id="value"></div>
          <section className="value" href="#value">
            <div className="container">
              <h1 class="text-left">FROM OUR EXPERIENCE FOUNDERS</h1>
              <h2 class="text-left">Strategic Value Proposition</h2>
              <div class="row">
                <div class="col-12">
                  <div className="vCarousel">
                    <div
                      id="carouselExampleIndicators"
                      className="carousel slide"
                      data-ride="carousel"
                      data-interval="false"
                    >
                      <ol class="carousel-indicators">
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="0"
                          class="active"
                        ></li>
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="1"
                        ></li>
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="2"
                        ></li>
                        <li
                          data-target="#carouselExampleIndicators"
                          data-slide-to="3"
                        ></li>
                      </ol>
                      <div class="carousel-inner">
                        <div class="carousel-item col-lg-3 col-md-6 active">
                          <div className="card border-light">
                            <img
                              src={
                                require("./Icons/Blues/PNG/With round.png")
                                  .default
                              }
                              className="img-top"
                              alt="logo"
                            />
                            <div className="card-body">
                              <h3 className="card-title">
                                Regional Development
                              </h3>
                              <p className="card-text">
                                KardiaChain is well-known in Vietnam and
                                Southeast Asia region for its technology and
                                innovation focus. A strategic partnership with
                                KardiaChain help accelerate new projects and
                                expand user adoption.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="carousel-item col-lg-3 col-md-6">
                          <div className="card card-border ">
                            <img
                              src={
                                require("./Icons/Blues/PNG/With round-1.png")
                                  .default
                              }
                              className="img-top"
                              alt="logo"
                            />
                            <div className="card-body">
                              <h3 className="card-title title1">
                                Vietnam Focus
                              </h3>
                              <p className="card-text">
                                Strategic partner with largest enterprises in
                                Vietnam, which range from e-commerce,
                                telecommunications, banking, real estate,
                                gaming, and other industries.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="carousel-item col-lg-3 col-md-6">
                          <div className="card border-light">
                            <img
                              src={
                                require("./Icons/Blues/PNG/With round-2.png")
                                  .default
                              }
                              className="img-top"
                              alt="logo"
                            />
                            <div className="card-body">
                              <h3 className="card-title title1">
                                Media Network
                              </h3>
                              <p className="card-text">
                                Strong affiliation with media and other
                                enterprise partners that can draw public
                                awareness and ensure successful launch.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="carousel-item col-lg-3 col-md-6">
                          <div className="card border-light">
                            <img
                              src={
                                require("./Icons/Blues/PNG/With round-3.png")
                                  .default
                              }
                              className="card-img-top"
                              alt="logo"
                            />
                            <div className="card-body">
                              <h3 className="card-title">Technical Support</h3>
                              <p className="card-text">
                                Our in-house top-notch technical team can help
                                to integrate your project into Kardia ecosystem
                                to gain immediate access to 100,000+ daily
                                active users via Kai Membership Integration.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Previous</span>
                                            </a>
                                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span class="sr-only">Next</span>
                                            </a> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row card-v">
                <div className="col-lg-3 col-md-6">
                  <div className="card border-light">
                    <img
                      src={require("./Icons/Blues/PNG/With round.png").default}
                      className="img-top"
                      alt="logo"
                    />
                    <div className="card-body">
                      <h3 className="card-title title2">
                        Regional Development
                      </h3>
                      <p className="card-text">
                        KardiaChain is well-known in Vietnam and Southeast Asia
                        region for its technology and innovation focus. A
                        strategic partnership with KardiaChain help accelerate
                        new projects and expand user adoption.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card card-border">
                    <img
                      src={
                        require("./Icons/Blues/PNG/With round-1.png").default
                      }
                      className="img-top"
                      alt="logo"
                    />
                    <div className="card-body">
                      <h3 className="card-title title1">Vietnam Focus</h3>
                      <p className="card-text">
                        Strategic partner with largest enterprises in Vietnam,
                        which range from e-commerce, telecommunications,
                        banking, real estate, gaming, and other industries.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card border-light">
                    <img
                      src={
                        require("./Icons/Blues/PNG/With round-2.png").default
                      }
                      className="img-top"
                      alt="logo"
                    />
                    <div className="card-body">
                      <h3 className="card-title title1">Media Network</h3>
                      <p className="card-text">
                        Strong affiliation with media and other enterprise
                        partners that can draw public awareness and ensure
                        successful launch.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="card border-light">
                    <img
                      src={
                        require("./Icons/Blues/PNG/With round-3.png").default
                      }
                      className="card-img-top"
                      alt="logo"
                    />
                    <div className="card-body">
                      <h3 className="card-title title1">Technical Support</h3>
                      <p className="card-text">
                        Our in-house top-notch technical team can help to
                        integrate your project into Kardia ecosystem to gain
                        immediate access to 100,000+ daily active users via Kai
                        Membership Integration.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* portfolios section */}
          <div id="portfolios" href="#portfolios"></div>
          <section className="portfolios">
            <div className="container">
              <h1 className="text-left">THROUGH THICK AND THIN</h1>
              <h2 className="text-left">Portfolios</h2>
              <div className="row">
                <div className="webImg">
                  <div className="img-container ">
                    <img
                      src={require("./Logos/Portfolio/AcknoLedger.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={require("./Logos/Portfolio/Ark Rivals.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div> */}
                  <div className="img-container ">
                    <img
                      src={require("./Logos/Portfolio/FATFI.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/COLIZEUM.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Copuppy.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/AIOZ.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/BoombCrypto .png").default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Creator.jpg").default}
                      alt="company logo"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={require("./Logos/Portfolio/Crucible.png").default}
                      alt="company logo"
                    />
                  </div> */}
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/cryptomeda.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/DareNFT .png").default}
                      alt="company logo"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={require("./Logos/Portfolio/DeHorizon.png").default}
                      alt="company logo"
                    />
                  </div> */}
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/DeHR.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Dragon Kart.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Drife.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Elmon.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Faraland.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/HeroFi .png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/KAWAII island.png").default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Kickpad.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/Kripto Galaxy Battle.png")
                          .default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/Loda Finance.png").default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/Mines of Dalarnia.png")
                          .default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Mist.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Moniwar .png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Moonka.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/moonstarter.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/My Defi Pet.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Mytheria.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/NFTtech.gif").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Orakuru.jpg").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container ">
                    <img
                      src={require("./Logos/Portfolio/Rage fan.png").default}
                      alt="company logo"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={
                        require("./Logos/Portfolio/Raze Network.png").default
                      }
                      alt="company logo"
                    />
                  </div> */}
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Real Realm.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Roseon.png").default}
                      alt="company logo"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={require("./Logos/Portfolio/Space Sip.png").default}
                      alt="company logo"
                    />
                  </div> */}
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Splyt.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/SubQuery.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Thetan.png").default}
                      alt="company logo"
                      className="thetan"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/ZUKIMOBA.png").default}
                      alt="company logo"
                      className="thetan"
                    />
                  </div>
                </div>

                <div className="mobImg">
                  <div className="img-container ">
                    <img
                      src={require("./Logos/Portfolio/AcknoLedger.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={require("./Logos/Portfolio/Ark Rivals.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div> */}
                  <div className="img-container ">
                    <img
                      src={require("./Logos/Portfolio/FATFI.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/COLIZEUM.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Copuppy.png").default}
                      alt="company logo"
                      className="old"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/AIOZ.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/BoombCrypto .png").default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Creator.jpg").default}
                      alt="company logo"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={require("./Logos/Portfolio/Crucible.png").default}
                      alt="company logo"
                    />
                  </div> */}
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/cryptomeda.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/DareNFT .png").default}
                      alt="company logo"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={require("./Logos/Portfolio/DeHorizon.png").default}
                      alt="company logo"
                    />
                  </div> */}
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/DeHR.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Dragon Kart.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Drife.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Elmon.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Faraland.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/HeroFi .png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/KAWAII island.png").default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Kickpad.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/Kripto Galaxy Battle.png")
                          .default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/Loda Finance.png").default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={
                        require("./Logos/Portfolio/Mines of Dalarnia.png")
                          .default
                      }
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Mist.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Moniwar .png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Moonka.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/moonstarter.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/My Defi Pet.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Mytheria.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/NFTtech.gif").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Orakuru.jpg").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Rage fan.png").default}
                      alt="company logo"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={
                        require("./Logos/Portfolio/Raze Network.png").default
                      }
                      alt="company logo"
                    />
                  </div> */}
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Real Realm.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Roseon.png").default}
                      alt="company logo"
                    />
                  </div>
                  {/* <div className="img-container black">
                    <img
                      src={require("./Logos/Portfolio/Space Sip.png").default}
                      alt="company logo"
                    />
                  </div> */}
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Splyt.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/SubQuery.png").default}
                      alt="company logo"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/Thetan.png").default}
                      alt="company logo"
                      className="thetan"
                    />
                  </div>
                  <div className="img-container">
                    <img
                      src={require("./Logos/Portfolio/ZUKIMOBA.png").default}
                      alt="company logo"
                      className="thetan"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*partner section*/}
          <div id="partners" href="#partners"></div>
          <section className="partners">
            <div className="container">
              <h1 className="text-left">ALONG THE JOURNEY</h1>
              <h2 className="text-left">Partners</h2>
              <h3 className="text-left">Enterprises</h3>
              <div className="row">
                <div className="webPImg">
                  <img
                    src={require("./Logos/PNG/Frame 2446.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2445.png").default}
                    alt="company logo"
                  />
                  <img
                    src={
                      require("./Logos/PNG/mai-linh-corporation-seeklogo.com.png")
                        .default
                    }
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2444.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2454.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2453.png").default}
                    alt="company logo"
                  />
                </div>
                <div className="mobPImg">
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2446.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2445.png").default}
                    alt="company logo"
                  />
                  <img
                    src={
                      require("./Logos/Color BG/PNG/mai-linh-corporation-seeklogo.com.png")
                        .default
                    }
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2444.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2454.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2453.png").default}
                    alt="company logo"
                  />
                </div>
              </div>
              <h4 className="text-left">Blockchain partners</h4>
              <div className="row">
                <div className="webPImg">
                  <img
                    src={require("./Logos/PNG/Frame 2319.png").default}
                    alt="company logo"
                  />
                  <img
                    src={
                      require("./Logos/PNG/certik-logotype-v-b 1.png").default
                    }
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2447.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2321.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2321-1.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2321-2.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2448.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2449.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2450.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/PNG/Frame 2321-3.png").default}
                    alt="company logo"
                  />
                </div>
                <div className="mobPImg">
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2319.png").default}
                    alt="company logo"
                  />
                  <img
                    src={
                      require("./Logos/Color BG/PNG/certik-logotype-v-b 1.png")
                        .default
                    }
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2447.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2321.png").default}
                    alt="company logo"
                  />
                  <img
                    src={
                      require("./Logos/Color BG/PNG/Frame 2321-1.png").default
                    }
                    alt="company logo"
                  />
                  <img
                    src={
                      require("./Logos/Color BG/PNG/Frame 2321-2.png").default
                    }
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2448.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2449.png").default}
                    alt="company logo"
                  />
                  <img
                    src={require("./Logos/Color BG/PNG/Frame 2450.png").default}
                    alt="company logo"
                  />
                  <img
                    src={
                      require("./Logos/Color BG/PNG/Frame 2321-3.png").default
                    }
                    alt="company logo"
                  />
                </div>
              </div>
              `
            </div>
          </section>
        </main>
        <section className="contact-form">
          <div className="container">
            <h1 className="text-center">Receive our latest news!</h1>
            <p className="text-center">
              Subscribe to receive our weekly newsletter and exciting news
            </p>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div className="formContact">
                  <form className="form-inline justify-content-center">
                    <div className="form-group mx-sm-3 ">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Your email"
                        style={{ marginBottom: 10 }}
                      />
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <img
                  className="logo"
                  src={require("./Icons/PNG/Logo-1.png").default}
                  alt="company logo"
                />
                <h3>Copyright © 2021. All rights reserved</h3>
              </div>
              <div className="col-lg-3">
                <h2>Legal</h2>
                <p>Term of Services</p>
                <p>Privacy Policy</p>
              </div>
              <div className="col-lg-2">
                <h4>Social Media</h4>
                <div className="social">
                  <img
                    src={require("./Icons/PNG/Social/facebook.png").default}
                    alt="Facebook"
                  />
                  <img
                    src={require("./Icons/PNG/Social/twitter.png").default}
                    alt="Twitter"
                  />
                  <img
                    src={require("./Icons/PNG/Social/google.png").default}
                    alt="Google"
                  />
                  <img
                    src={require("./Icons/PNG/Social/linkedin.png").default}
                    alt="Linkedin"
                  />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    </div>
  );
}

export default App;
